









import Vue, { PropType } from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'DeleteDivision',
  components: { DeleteModal },
  props: {
    division: {
      type: Object as PropType<{ id: string; }>,
      required: true,
    },
  },
  computed: {
    orgId(): string {
      return this.$route.params.orgId;
    },
    url(): string {
      return `/org/${this.orgId}/division/${this.division.id}`;
    },
  },
  methods: {
    async onDelete() {
      this.$emit('delete');
    },
  },
});
