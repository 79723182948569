





























import Vue from 'vue';
import { OrgModel } from '@/types/org';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import NewDivision from '@/views/admin/org/divisions/NewDivision.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import EditDivision from '@/views/admin/org/divisions/EditDivision.vue';
import DeleteDivision from '@/views/admin/org/divisions/DeleteDivision.vue';

export default Vue.extend({
  name: 'ListDivisions',
  components: {
    DeleteDivision,
    EditDivision,
    ListWithFilter,
    NewDivision,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/org/${this.details.id}/division/info`;
    },
    details(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          field: 'description',
          label: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'projectsNumber',
          label: 'Projects on Division',
          field: 'projectsNumber',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
});
