




































import Vue, { VueConstructor } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'EditDivision',
  components: {
    EditBtn,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    division: {
      type: Object,
      required: true,
    },
  },
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'put',
          url: `/org/${this.$route.params.orgId}/division/${this.division.id}`,
        },
      };
    },
  },
  data() {
    return {
      model: {
        label: '',
        description: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      this.error = '';
      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.notify('Division updated');
      (this.$refs.modal as any).close();
      this.model.label = '';
      this.model.description = '';
      this.$emit('submit');
    },
    openModal() {
      this.model.label = this.division.label;
      this.model.description = this.division.description;
      (this.$refs.modal as any).open();
    },
  },
});
